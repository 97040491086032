'use client';

export interface HVACChatHeroProps {
	testId?: string;
}
import { HeadingText } from '@/components/atoms/HeadingText';
import { Badge } from '@/components/Badge';
import { IconArrowRight } from '@/components/icons';
import { SpaceWow } from '@/components/SpaceWow';
import ChatPicture from '@/public/img/infinity-lite/ProductCard_Chat.webp';
import Image from 'next/image';
import openChat from '@/helpers/hubspot/openChat';
import { Button } from '@/components/atoms/Button';

const HVACChatHero = (props: HVACChatHeroProps) => {
	const { testId = 'HVACChatHero' } = props;

	return (
		<div data-testid={testId} className="hvac-container">
			<section className="mb-10 grid max-w-7xl gap-10 lg:mx-auto lg:mb-14 lg:grid-cols-12 lg:gap-4">
				<div className="lg:col-span-5 lg:flex lg:h-full lg:flex-col lg:justify-center">
					<div className="grid place-items-center lg:place-items-start">
						<Badge color="sky">Live Chat</Badge>
					</div>

					<SpaceWow spacer_04 lg__spacer_02 />

					<HeadingText tag="h2" className="lg:text-start">
						Chat with an Advisor
					</HeadingText>

					<SpaceWow spacer_03 lg__spacer_04 />

					<p
						className="text-balance text-center text-xl font-normal tracking-tighter text-black
						lg:text-start lg:text-2xl"
					>
						Have an HVAC question? Whether it&apos;s about repairs,
						troubleshooting, or choosing a new system, we&apos;re here to help!
					</p>

					<SpaceWow spacer_06 lg__spacer_08 />

					<div className="grid place-items-center gap-3 lg:place-items-start lg:gap-4">
						<Button
							data-testid={testId}
							variant="text"
							onClick={openChat}
							className="group flex items-center gap-1 text-xl tracking-tight text-sky-700 hover:text-sky-800
							lg:text-2xl"
						>
							<span>Get help now</span>
							<IconArrowRight
								className="h-6 w-6 fill-sky-700 group-hover:fill-sky-800 lg:h-7 lg:w-7"
								aria-hidden="true"
							/>
						</Button>
					</div>
				</div>
				<Image
					src={ChatPicture}
					alt="Chat with an HVAC technician"
					className="overflow-clip rounded-2xl lg:col-span-6 lg:col-start-7 lg:rounded-5xl"
				/>
			</section>
		</div>
	);
};

export default HVACChatHero;
