'use client';

import cn from 'classnames';
import Script from 'next/script';
import { useEffect } from 'react';

export interface VideoEmbedProps {
	testId?: string;
	videoId: string;
	videoTitle: string;
	videoSettings?: VideoSettings;
	className?: string;
}

interface VideoSettings {
	suggestRelated?: boolean;
	playInline?: boolean;
	autoplay?: boolean;
	showVideoControls?: boolean;
	loop?: boolean;
	disableKbControls?: boolean;
	disableFullscreen?: boolean;
}

export const onPlayerStateChange = (event: YT.OnStateChangeEvent) => {
	const iframe = event.target.getIframe();
	const videoData = iframe.dataset;
	const videoId = videoData.videoid || 'unknown_video_id';
	const videoTitle = videoData.videotitle || 'unknown_video_title';
	switch (event.data) {
		case window.YT.PlayerState.PLAYING:
			window.heap?.track('Video Play', {
				'Video ID': videoId,
				title: videoTitle,
			});
			break;
		case window.YT.PlayerState.ENDED:
			window.heap?.track('Video Finished', {
				'Video ID': videoId,
				title: videoTitle,
			});
			break;
	}
};

/**
 * VideoEmbed component to embed a YouTube video using the YouTube IFrame Player API.
 *
 *
 * @example
 * <VideoEmbed
 *   videoId="dQw4w9WgXcQ"
 *   videoTitle="Example Video"
 *   videoSettings={{
 *     suggestRelated: true,
 *     playInline: true,
 *     showVideoControls: true,
 *     autoplay: false,
 *     loop: false,
 *     disableKbControls: false,
 *   }}
 * />
 */
const VideoEmbed = (props: VideoEmbedProps) => {
	const {
		testId = 'VideoEmbed',
		videoId,
		videoTitle,
		videoSettings,
		className,
	} = props;

	useEffect(() => {
		function onYouTubeIframeAPIReady() {
			const videos = Array.from(
				document.querySelectorAll('[data-videoid]'),
			) as HTMLIFrameElement[];
			videos.map((video) => {
				const { videoid } = video.dataset;

				if (!videoid) return;

				new window.YT.Player(videoid, {
					videoId: videoid,
					events: {
						onStateChange: onPlayerStateChange,
					},
					playerVars: {
						rel: videoSettings?.suggestRelated ? 1 : 0,
						playsinline: videoSettings?.playInline ? 1 : 0,
						controls: videoSettings?.showVideoControls ? 1 : 0,
						autoplay: videoSettings?.autoplay ? 1 : 0,
						loop: videoSettings?.loop ? 1 : 0,
						disablekb: videoSettings?.disableKbControls ? 1 : 0,
						fs: videoSettings?.disableFullscreen ? 0 : 1,
					},
				});
			});
		}

		if (!window.YT) {
			window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
		} else {
			onYouTubeIframeAPIReady();
		}

		return () => {
			window.onYouTubeIframeAPIReady = undefined;
		};
	}, [videoSettings]);

	return (
		<div test-testid={testId}>
			<Script src="https://www.youtube.com/iframe_api" />

			<div
				id={videoId}
				data-videoid={videoId}
				data-videotitle={videoTitle}
				className={cn(
					{
						// Default styling for the video embed
						'aspect-video h-auto w-full rounded-xl lg:mx-auto lg:max-w-2xl lg:rounded-2xl':
							!className,
					},
					className,
				)}
			></div>
		</div>
	);
};

export default VideoEmbed;
